import React from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import CookieConsent from "react-cookie-consent";
import "./styles.css";

const Hero = styled("div")`
  margin: auto;
  max-width: 1000px;
  min-height: 70vh;
  padding: 20px;
  h1 {
    margin-top: 6vh;
    margin-bottom: 50px;
    font-size: 32px;
  }
  p {
    margin-top: 30px;
  }
  display: flex;
  > div {
    width: 50%;
  }
  img.dashboard {
    margin-top: 50px;
    margin-left: 20px;
    position: absolute;
  }
  button {
    font-size: 18px;
  }
  @media (max-width: 800px) {
    flex-direction: column-reverse;
    > div {
      width: 100%;
    }
    img.dashboard {
      /* position: relative; */
      z-index: -1;
      max-width: 100%;
      margin-top: -150px;
    }
  }
`;

const Features = styled("div")`
  max-width: 1000px;
  margin: auto;
  margin-top: 100px;
  padding: 20px;

  .list {
    margin: 0;
    margin-top: 50px;
    .item {
      margin-bottom: 140px;
      list-style-type: none;
      display: flex;
      align-items: center;
      img {
        max-width: 300px;
        margin: 20px;
        vertical-align: middle;
      }
      :nth-child(even) {
        flex-direction: row-reverse;
        img {
          margin-left: 70px;
        }
      }
      :nth-child(odd) {
        img {
          margin-right: 70px;
        }
      }
    }
  }

  @media (max-width: 800px) {
    .list .item {
      flex-direction: column;
      :nth-child(even) {
        flex-direction: column;
        img {
          margin-left: 0px;
        }
      }
      :nth-child(odd) {
        img {
          margin-right: 0px;
        }
      }
    }
  }
`;

const CTA = styled("div")`
  max-width: 800px;
  margin: auto;
  margin-top: 100px;
  text-align: center;
  padding: 20px;

  form {
    padding: 30px;
    display: flex;
    /* flex-wrap: wrap; */
    align-items: center;
    input {
      font-size: 20px;
      margin-right: 10px;
      margin-bottom: 20px;
      /* max-width: 240px; */
    }
  }
  button {
    font-size: 18px;
    min-width: 225px;
    margin-bottom: 20px;
  }
  li {
    margin-bottom: 20px;
  }
  .offer {
    margin-top: 100px;
    text-align: left;
    max-width: 600px;
    margin-left: 100px;
  }

  @media (max-width: 800px) {
    form {
      flex-direction: column;
    }
    .offer {
      margin-left: 0;
    }
  }
`;

const Quote = styled("div")`
  max-width: 800px
  background: #eee;
  border-radius: 100px;
  padding: 80px;
  margin: auto;
  margin-top: 75px;  
  font-size: 20px;
  line-height: 150%;
  ::before {
    content: "«";
    display: block;
    font-size: 75px;
    float: left;
    color: #ddd;
    margin-top: -20px;
  }
  ::after {
    content: "»";
    display: block;
    font-size: 75px;
    float: right;
    color: #ddd;
    margin-top: -40px;
  }  
  .name { font-size: 16px; margin-top: 20px; }
  .title { font-size: 16px; color: #777; }

  @media (max-width: 800px) {
    border-radius: 40px;
    padding: 40px;
    font-size: 16px;
    margin: 20px;
  }
`;

const Footer = styled("footer")`
  margin-top: 100px;
  background: #333;
  color: #fff;
  > div {
    display: flex;
    margin: auto;
    max-width: 700px;
    padding: 40px;
    line-height: 200%;
    > div {
      width: 50%;
    }
    > div:last-child {
      text-align: right;
    }
  }
  a {
    color: #e370e2;
  }
  .twitter {
    display: flex;
    align-items: center;
    svg {
      margin-right: 10px;
    }
  }
  @media (max-width: 800px) {
    padding: 20px;
    > div {
      flex-direction: column;
      > div {
        width: 100%;
      }
      > div:last-child {
        text-align: left;
      }
    }
  }
`;

const Logo = styled("img")`
  width: 80px;
  margin-top: 20px;
  opacity: 0.3;
`;

function logCta() {
  if (document.querySelectorAll("#log-cta").length) return;
  var img = document.createElement("img");
  img.id = "log-cta";
  img.src =
    "https://px.ads.linkedin.com/collect/?pid=1329316&conversionId=1141740&fmt=gif";
  document.body.appendChild(img);
}

function App() {
  return (
    <div className="App">
      <Hero>
        <div>
          <Logo src="/logo-black.png" alt="Integration heroes" />
          <h1>
            Leverage Technology to Optimize Your Customer Support Costs and
            Increase Revenue
          </h1>
          <p>
            Do you feel that your customer support team should leverage modern
            technology to <b>optimize its processes</b>?
          </p>
          <p>
            We help companies find and implement technological solutions to help
            their customers faster, <b>reduce support costs</b> and keep
            customer support representatives happy by{" "}
            <b>automating boring tasks</b>.
          </p>
          <br />
          <button onClick={() => document.querySelector(".name").focus()}>
            Book a free workshop
          </button>
        </div>
        <div>
          <img src="/Dashboard.svg" alt="Dashboard" className="dashboard" />
        </div>
      </Hero>

      <Features>
        <h2>We can help you with</h2>
        <div className="list">
          <div className="item">
            <img src="/g11683.svg" className="illustration" />
            <div>
              <h2>
                Complete automation of a large share of first-line incoming
                contacts
              </h2>
              <p>
                Modern machine learning systems are able to accurately
                categorize customer requests and perform an automated action
                without any human involvement.
              </p>
              <p>
                Results:{" "}
                <b>Faster replies to customers, lower support costs.</b>
              </p>
            </div>
          </div>
          <div className="item">
            <img src="/g12824.svg" className="illustration" />
            <div>
              <h2>Augmentation of support agent work</h2>
              <p>
                We will build solutions that will help your agents make faster
                and more accurate decisions. For example, our systems can
                automatically highlight the data relevant to the customer’s
                problem or perform automated background checks to present a
                recommendation to the agent.
              </p>
              <p>
                Results:{" "}
                <b>
                  Less tedious investigations by agents, faster and better help
                  to customers.
                </b>
              </p>
            </div>
          </div>
          <div className="item">
            <img src="/Group 2.svg" className="illustration" />
            <div>
              <h2>Revenue generating opportunities in customer support</h2>
              <p>
                We can help integrate your customer support systems and
                processes with other departments in your company to explore and
                start utilizing revenue growth opportunities originating in the
                customer support team.
              </p>
              <p>
                Result:{" "}
                <b>
                  Shift from being purely a cost center to facilitating sales
                </b>
                .
              </p>
            </div>
          </div>
          <div className="item">
            <img src="/g10385.svg" className="illustration" />
            <div>
              <h2>Data collection and operational monitoring</h2>
              <p>
                By collecting relevant data we can help you make better
                decisions and answer questions such as:
              </p>
              <ul>
                <li>what are customers contacting us about? </li>
                <li>
                  what is the hourly performance of individual agents and teams?
                  who are the most valuable customers we should focus on first?{" "}
                </li>
                <li>
                  what are the biggest skill improvement opportunities for our
                  agents?{" "}
                </li>
                <li>how happy are customers with our replies? </li>
                <li>
                  given new product or feature launches, how many more agents
                  will we need?
                </li>
              </ul>
              <p>
                Results: <b>Efficient decisions based on data</b>.
              </p>
            </div>
          </div>
        </div>
      </Features>

      <CTA>
        <h2>Book a Free Evaluation Workshop</h2>
        <form
          acceptCharset="utf-8"
          action="https://formspree.io/hi@integrationheroes.com"
          method="post"
          onSubmit={logCta}
        >
          <input
            className="name"
            type="text"
            placeholder="Your Name"
            name="name"
            required
          />
          <input
            type="text"
            placeholder="you@company.com"
            name="email"
            required
          />
          <button type="submit">Get a workshop</button>
        </form>
        <p>We will contact you to arrange time for the workshop.</p>
        <div className="offer">
          <p>Here is what we're offering you:</p>
          <h3>
            Fixed Price Customer Support Center Digitalization Sprint{" "}
            <sup>&trade;</sup>
          </h3>
          <ul>
            <li>
              One-day <b>Free</b> Evaluation Workshop
            </li>
            <li>2-week Digital Transformation Research Sprint</li>
            <li>Digital Strategy Report and Implementation Plan</li>
            <li>
              <b>Guaranteed</b> reduction of at least three types of oveheads
            </li>
            <li>
              At least one <b>revenue generating insight</b>
            </li>
            <li>
              <b>Zero</b> upfront investment
            </li>
          </ul>
          <br />
          <p>
            <a onClick={() => document.querySelector(".name").focus()}>
              Get in touch to learn more
            </a>
            , we reply instantly!
            <br />
            <br />
          </p>
        </div>
      </CTA>

      <Quote>
        “In 2019 and beyond, it’s no longer an option for brands not to invest
        in their customer care functions.
        <br /> With so many options for the consumer of today, extending
        efficient and quick experiences to your customer base is imperative.{" "}
        <br />
        <br />
        Forward thinking brands who innovate and harness emerging technology
        such as automation, bots and voice, in line with platform changes and
        capabilities, will win the race for the digitally empowered customer.”
        <div className="name">Joshua March</div>
        <div className="title">CEO, Conversocial</div>
      </Quote>

      <CTA style={{ maxWidth: 500 }}>
        <p>
          {" "}
          Put your team in the front row of customer support technological
          advancements.
        </p>
        <br />
        <button
          onClick={() => document.querySelector(".name").focus()}
          style={{ padding: "20px 40px" }}
        >
          Book a free workshop
        </button>
      </CTA>

      <Footer>
        <div>
          <div>
            <label>CONTACT DETAILS</label>
            <br />
            <br />
            <a href="mailto:hi@integrationheroes.com">
              hi@integrationheroes.com
            </a>
            <br />
            <a href="tel:+358 942 550 354">+358 942 550 354</a>
            <br />
            <br />
            <a
              href="https://twitter.com/integrationhero"
              target="_blank"
              className="twitter"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 72 72"
                width="36"
                height="36"
              >
                <path fill="none" d="M0 0h72v72H0z" />
                <path
                  className="icon"
                  fill="#e370e2"
                  d="M68.812 15.14c-2.348 1.04-4.87 1.744-7.52 2.06 2.704-1.62 4.78-4.186 5.757-7.243-2.53 1.5-5.33 2.592-8.314 3.176C56.35 10.59 52.948 9 49.182 9c-7.23 0-13.092 5.86-13.092 13.093 0 1.026.118 2.02.338 2.98C25.543 24.527 15.9 19.318 9.44 11.396c-1.125 1.936-1.77 4.184-1.77 6.58 0 4.543 2.312 8.552 5.824 10.9-2.146-.07-4.165-.658-5.93-1.64-.002.056-.002.11-.002.163 0 6.345 4.513 11.638 10.504 12.84-1.1.298-2.256.457-3.45.457-.845 0-1.666-.078-2.464-.23 1.667 5.2 6.5 8.985 12.23 9.09-4.482 3.51-10.13 5.605-16.26 5.605-1.055 0-2.096-.06-3.122-.184 5.794 3.717 12.676 5.882 20.067 5.882 24.083 0 37.25-19.95 37.25-37.25 0-.565-.013-1.133-.038-1.693 2.558-1.847 4.778-4.15 6.532-6.774z"
                />
              </svg>
              Twitter
            </a>
            <a href="http:integrationheroes.com/privacy.html">Privacy Policy</a>
          </div>
          <div>
            Kampinkuja 2 <br />
            00100, Helsinki
            <br />
            2452141-7
            <br />
            <br />
            <br />
            Integration Heroes {new Date().getFullYear()}
          </div>
        </div>
      </Footer>
      <CookieConsent
        location="bottom"
        buttonText="I agree"
        cookieName="myAwesomeCookieName2"
        style={{ background: "#eee", color: "#000" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        <span style={{ fontSize: 12 }}>This website uses cookies.</span>
      </CookieConsent>
    </div>
  );
}

const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);
